<template>
  <v-card class="dxa-modal video-container">
    <div class="embed-container">
      <iframe
        title="Video"
        v-if="video.videoPlatform === videoPlatformEnum.VIMEO"
        :src="`https://player.vimeo.com/video/${video.videoId}?portrait=0&byline=0&title=0`"
        frameborder="0"
        allowfullscreen
      ></iframe>
      <iframe
        title="Video"
        v-if="video.videoPlatform === videoPlatformEnum.YOUTUBE"
        :src="`https://www.youtube.com/embed/${video.videoId}?rel=0,showinfo=0,controls=0`"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </v-card>
</template>

<script>
import { videoPlatformEnum } from "@/shared/enums/VideoPlatformEnum";

export default {
  name: "VideoDialog",
  data() {
    return {
      videoPlatformEnum,
    };
  },
  props: {
    video: Object,
  },
};
</script>

<style scoped>
.video-container {
  max-width: 650px;
  border: 15px solid var(--white);
  border-radius: 4px;
}
</style>
