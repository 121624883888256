<template>
  <div class="card-formatted-currency-container">
    <div
      v-show="totalInvested.length"
      v-for="(item, i) in totalInvested"
      :key="i"
    >
      <div class="card-formatted-currency-bg">
        <span>{{ `${cardTitle} (${getCurrencyPrefix(item.currency)})` }}</span>
        <h1>{{ formatCurrency(item.currency, item.value) }}</h1>
      </div>
    </div>
    <div v-if="!totalInvested.length" class="card-formatted-currency-bg">
      <span>{{ `${cardTitle} (-)` }}</span>
      <h1>-</h1>
    </div>
  </div>
</template>

<script>
import {
  formatCurrency,
  getCurrencyPrefix,
} from "@/shared/helpers/currencyHelper";

export default {
  name: "CardFormattedCurrency",
  props: {
    totalInvested: Array,
    cardTitle: String,
  },
  data() {
    return {
      formatCurrency: formatCurrency,
      getCurrencyPrefix: getCurrencyPrefix,
    };
  },
};
</script>

<style scoped>
.card-formatted-currency-bg {
  background: var(--white);
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  padding: 20px;
  width: 300px;
  min-height: 90px;
}

.card-formatted-currency-bg > span {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  display: block;
  margin-bottom: 8px;
}

.card-formatted-currency-bg > h1 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.card-formatted-currency-container {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  margin-top: 10px;
  flex-wrap: wrap;
}
</style>
